import logo from "./logo.svg";
import "./App.css";
import $ from "jquery";
function App() {
  function moveDiv() {
    var $span = $("#random");

    $span.fadeOut(270, function () {
      var maxLeft = $(window).width() - $span.width();
      var maxTop = $(window).height() - $span.height();
      var leftPos = Math.floor(Math.random() * (maxLeft + 1));
      var topPos = Math.floor(Math.random() * (maxTop + 1));

      $span.css({ left: leftPos, top: topPos }).fadeIn(270);
    });
  }

  moveDiv();
  setInterval(moveDiv, 270);

  return (
    <div className="App">
      <header className="App-header">
        <p style={{ fontSize: "200%" }}>LOSER 2021-2022 Season</p>

        <t>(and 2020-2021 season)</t>
        <b>Luke Putnam</b>
        <img
          id="smiley"
          src="https://as1.ftcdn.net/v2/jpg/00/46/47/94/1000_F_46479479_PqnQXyf4mCHHcipv2ScFZ3T30Q9JOXod.jpg"
        />
        <p style={{ zindex: "10", color: "black" }}>
          LOOOOOOOL LUKE YOU FUCKING SUCK LMAO GET OWNED!!!
        </p>

        <br />
        <br />
        <div className="pics">
          <img className="pic" src="https://i.imgur.com/irV6PCz.jpg"></img>
          <img
            src={"https://c.tenor.com/3xUw0rsM0t4AAAAd/fortnite-take-the-l.gif"}
            className="App-logo"
            alt="logo"
          />
          <img className="pic" src="https://i.imgur.com/a6AYugM.jpg"></img>
        </div>
        <br />

        <div className="el-wrap x">
          <div className="el y" style={{ color: "black" }}>
            HAHAHHAHAHAHAH LMAO
          </div>
          <br />
          <br />
          <br />
          <a>Look at this lil weenie. Two years in a row??? </a>
        </div>
        <div id="container">
          <span id="random">
            <img
              style={{ height: "200px" }}
              src="https://image.shutterstock.com/z/stock-vector-stinky-poop-pile-with-flies-cartoon-253044613.jpg"
            />
            STINKY STINKY!!!
          </span>{" "}
        </div>
      </header>
    </div>
  );
}

export default App;
